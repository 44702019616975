import React, { useState } from 'react'
import WorkWithUs from '../../molecules/WorkWithUs'
import './styles.scss'
import { navigate } from 'gatsby'
import { EMAIL_CONTACT } from '../../../helpers/constants'

const Contact = () => {
  const [phoneValue, setPhoneValue] = useState('')

  const onPhoneChange = (value) => {
    const newPhoneValue = value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')

    setPhoneValue(newPhoneValue)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const formValues = Object.fromEntries(new FormData(e.target))
    const url = 'https://vx3nuvayzb.execute-api.us-east-1.amazonaws.com/prod/contact'
    const payload = {
      ...formValues,
      to: EMAIL_CONTACT,
      subject: `${formValues.name}'s message from website`,
    }

    await fetch(url, { method: 'POST', body: JSON.stringify(payload) })
      .then(() => navigate('/thanks'))
      .catch((err) => console.error(err))
  }

  return (
    <section className="faleconosco">
      <div className="faleconosco__banner"></div>
      <div className="faleconosco__container">
        <div className="faleconosco__title">
          <span>Fale conosco</span>
        </div>
        <div className="faleconosco__form">
          <p>
            <span>Quer saber como nós podemos ajudar o seu negócio? </span>
            Preencha o formulário a seguir!
          </p>
          <form onSubmit={(e) => onSubmit(e)}>
            <input
              className="faleconosco__form--input"
              type="text"
              name="name"
              placeholder="Nome"
              required
            />
            <input
              className="faleconosco__form--input"
              type="text"
              name="phone"
              placeholder="Telefone"
              value={phoneValue}
              onChange={(e) => onPhoneChange(e.target.value)}
              required
              maxLength={12}
            />
            <input
              className="faleconosco__form--input"
              type="email"
              name="email"
              placeholder="E-mail"
              required
            />
            <textarea
              required
              type="text"
              name="message"
              placeholder="Mensagem"
              className="faleconosco__form--message faleconosco__form--input"
            />
            <button type="submit">Enviar</button>
          </form>
        </div>
        <div className="faleconosco__infos">
          <ul>
            <li>
              <p>Fale conosco via WhatsApp</p>
              <p>
                <span></span>{' '}
                <a href="https://wa.me/5511917690665" target="_blank" rel="noreferrer">
                  (11) 9 1769-0665
                </a>
              </p>
            </li>
            <li>
              <p>Envie-nos uma mensagem</p>
              <p>
                <span></span>
                <a href="mailto:contato@digitalrepublic.com.br">
                  contato@digitalrepublic.com.br
                </a>
              </p>
            </li>
            <li>
              <p>Onde estamos</p>
              <p>
                <span></span>Rua Catequese, 227 - Butantã, 2º andar São Paulo - SP, CEP
                05502-020
              </p>
            </li>
          </ul>
          <ul>
            <li className="socialmedia">
              <div className="socialmedia__linkdin">
                <a
                  href="https://www.linkedin.com/company/digitalrepublicbr/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Linkedin"
                >
                  Linkedin
                </a>
              </div>

              <div className="socialmedia__instagram">
                <a
                  href="https://www.instagram.com/digitalrepublic.br/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram"
                >
                  Instagram
                </a>
              </div>

              <div className="socialmedia__facebook">
                <a
                  href="https://www.facebook.com/digitalrepublicbrasil/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook"
                >
                  Facebook
                </a>
              </div>

              <div className="socialmedia__youtube">
                <a
                  href="https://www.youtube.com/channel/UC1RVE9-6woKRyMiIyyDwqSw "
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Youtube"
                >
                  Youtube
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <WorkWithUs text="Trabalhe conosco" redirect="/trabalhe-conosco" />
    </section>
  )
}
export default Contact
