import * as React from "react"
import Layout from "../templates/layout"
import Seo from "../helpers/seo"
import Contact from "../components/organisms/Contact"

const FaleConosco = () => {
  return (
    <Layout page="FaleConosco">
      <Contact />
    </Layout>
  )
}
export const Head = () => <Seo title="Fale conosco" />

export default FaleConosco
